import { useNavigate } from 'react-router-dom';
import styles from './PaymentRequiredError.module.scss';
import React, { useState } from 'react';
import Title from '../../../component/Title';
import * as routes from '../../../navigation/routes';
import CustomButton from '../../../component/CustomButton';
import SubscriptionPlanOptions from '../../../component/SubscriptionPlanOptions';
import { useUser } from '../../../hooks/useUser';

const PaymentRequiredError = () => {
  const navigate = useNavigate();
  const [showSubscriptionPlanOptions, setSubscriptionPlanOptions] = useState(false);
  const [, , { isPremium }] = useUser();

  const userSelectPremium = () => {
    if (!isPremium) {
      setSubscriptionPlanOptions(true);
    } else {
      navigate('/' + routes.BillingRoute);
    }
  };
  return (
    <div className={styles.soon}>
      <Title>Cool Stuff coming your way | Kirgawa</Title>
      <h1 className={styles.head}>Payment Required</h1>
      <p className={styles.text}>
        It seems like your subscription has ended or that you haven't paid. Although you can keep
        using your existing subscription, you won't have access to premium services until you
        upgrade.
      </p>
      <div className={styles.input}>
        <CustomButton className={styles.button} primary onClick={() => userSelectPremium()}>
          Complete Payment
        </CustomButton>
        <div className={'spacer'} />
        <CustomButton
          invert
          className={styles.button}
          onClick={() => navigate(routes.DashboardRoute)}
        >
          Go Back To Dashboard
        </CustomButton>
      </div>
      <SubscriptionPlanOptions
        isShown={showSubscriptionPlanOptions}
        setIsShown={setSubscriptionPlanOptions}
      />
    </div>
  );
};

export default PaymentRequiredError;
