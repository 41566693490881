import Capitalize from 'lodash/capitalize';
import {
  LOCAL_STORAGE_TOKEN_EXPIRE,
  LOCAL_STORAGE_TOKEN_KEY,
  LOCAL_STORAGE_USER_KEY,
} from './constant';
import { getCurrentPage, getEnv } from './general';
import $http from '../services/api';
import { localForage } from '../store/persist';

export const sessionStorageInfo = { userAuth: LOCAL_STORAGE_USER_KEY };

export function getStoredBrowserUser() {
  try {
    return JSON.parse(window.localStorage.getItem(sessionStorageInfo.userAuth) || '');
  } catch (e) {
    return {};
  }
}
export function storeUserToBrowser(payload: any) {
  window.localStorage.setItem(sessionStorageInfo.userAuth, JSON.stringify(payload));
}

export function getLoginToken() {
  const { type, token, ...rest } = getStoredBrowserUser();
  if (!type || !type || !rest) return false;
  return `${Capitalize(type)} ${token}`;
}

export function clearUserSessionStore() {
  window.localStorage.removeItem(sessionStorageInfo.userAuth);
}

export async function logout() {
  clearUserSessionStore();
  $http
    .get('auth/logout')
    .then(() => console.log('logged out'))
    .catch(() => console.log('logged out'));

  await localForage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE);
  await localForage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  redirectToLogin();
}

export function redirectToLogin(page = window.location.origin) {
  getCurrentPage();
  window.location.href = `${getEnv('LOGIN_URL', '/auth/login')}?rbt=${page}`;
}

export const encrypt = (value: any) => {
  try {
    const text = JSON.stringify(value);
    const salt = getEnv('REACT_APP_SLA_PAO');
    const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n: number) => ('0' + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code: number) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return (
      text
        .split('')
        .map(textToChars)
        // @ts-ignore
        .map(applySaltToChar)
        .map(byteHex)
        .join('')
    );
  } catch (e) {
    return null;
  }
};

export const decrypt = (encoded: string) => {
  try {
    const salt = getEnv('REACT_APP_SLA_PAO');
    const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
    const applySaltToChar = (code: number) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return JSON.parse(
      `${encoded}`
        .match(/.{1,2}/g)
        ?.map((hex) => parseInt(hex, 16))
        ?.map(applySaltToChar)
        ?.map((charCode) => String.fromCharCode(charCode))
        ?.join('') || ''
    );
  } catch (e) {
    return null;
  }
};

export const compareEncrypt = (value: string, hash: string) => {
  const sameHash = encrypt(value) === hash;
  const sameValue = decrypt(hash) === value;
  return sameHash && sameValue;
};
