import { GuestsRoute, POSPaymentRoute } from '../navigation/routes';

export const LOCAL_STORAGE_USER_KEY = '__local_storage_user__';
export const LOCAL_STORAGE_TOKEN_KEY = '__local_storage_token__';
export const LOCAL_STORAGE_TOKEN_EXPIRE = '__local_storage_token_$ETA__';
export const PUSHER_GLOBAL_CHANNEL = 'global';
export const PUSHER_USER_PRIVATE_CHANNEL = 'private-user-';

export const PAID_ONLY_ROUTES = [GuestsRoute, POSPaymentRoute];

// starter / essentials
export const MAXIMUM_ACTIVITIES_FOR_STARTER = 5;
export const MAXIMUM_ACTIVITIES_FOR_STARTER_WORDS = 'five';
export const MAXIMUM_EVENTS_FOR_STARTER = 2;
export const MAXIMUM_EVENTS_FOR_STARTER_WORDS = 'two';
export const MAXIMUM_TICKETS_FOR_STARTER = 2;

// business / pro business

// enterprise/ enterprise
