import { useRecoilCallback } from 'recoil';
import {
  supportedBanks,
  supportedBanksAtomState,
  supportedCountries,
  supportedCountriesAtomState,
} from '../store/atoms';
import React from 'react';
import { IPaymentBankPayload, IPaymentOptions } from '../interface/payment';
import { getIPDataInfo } from '../services/general';

export interface ICachedData<T> {
  data?: T;
  provider: IPaymentOptions;
  country?: string;
  currency?: string;
}
export default function useCache({
  provider = 'paystack',
  currency = 'NGN',
  country = 'NIGERIA',
}: ICachedData<any>) {
  const [isLoading, setIsLoading] = React.useState(true);

  const updateBankInfo = useRecoilCallback(
    ({ snapshot, set }) =>
      async (payload?: IPaymentBankPayload) => {
        const key = `${payload?.provider}-${payload?.country}-${payload?.currency}`.toUpperCase();
        const banks = await snapshot.getLoadable(supportedBanksAtomState).contents; // pre-fetch user info
        if (banks[key] && banks[key].length) return;
        const sn = await snapshot.getLoadable(supportedBanks({ key, payload })).contents; // pre-fetch user info
        set(supportedBanksAtomState, (prev) => ({ ...prev, [key]: sn }));
      }
  );
  const updateCountryList = useRecoilCallback(
    ({ snapshot, set }) =>
      async (provider: IPaymentOptions) => {
        const countries = await snapshot.getLoadable(supportedCountriesAtomState).contents; // pre-fetch user info
        if (countries[provider] && countries[provider].length) return;
        const sn = await snapshot.getLoadable(supportedCountries(provider)).contents; // pre-fetch user info
        set(supportedCountriesAtomState, (prev) => ({ ...prev, [provider]: sn }));
      }
  );

  const reset = useRecoilCallback(({ set }) => async () => {
    await set(supportedBanksAtomState, {});
    await set(supportedCountriesAtomState, {});
  });

  React.useEffect(() => {
    getIPDataInfo();
    if (!isLoading) {
      updateBankInfo({ provider, currency, country });
      updateCountryList(provider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 900);
  }, []);

  // to avoid re-rendering please do not return react state, recoilState or recoilValue here
  // only return pure data and functions
  return { isLoading, updateCountryList, updateBankInfo, reset };
}
