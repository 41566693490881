import $http from './api';

export const getSubscriptionById = async (id: string) => {
  try {
    if (!id) return;
    const { data } = await $http.get(`pub/subscriptions/${id}`);
    return data;
  } catch (error) {
    return null;
  }
};

export const getInfo = async () => {
  try {
    const { data } = await $http.get(`profile/self`);
    return data;
  } catch (error) {
    return null;
  }
};
