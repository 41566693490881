import styles from './NewPricingIntervalSelector.module.scss';
import clsx from 'clsx';
import { IPaymentInterval } from '../../interface/payment';

type props = {
  priceInterval: IPaymentInterval;
  setPriceInterval: React.Dispatch<React.SetStateAction<IPaymentInterval>>;
};

const NewPricingIntervalSelector = ({ priceInterval, setPriceInterval }: props) => {
  const changeInterval = (interval: IPaymentInterval) => () => setPriceInterval(interval);

  return (
    <div className={styles.container}>
      <div
        onClick={changeInterval('yearly')}
        className={clsx(styles.left, {
          [styles['active-interval']]: priceInterval === 'yearly',
        })}
      >
        Annual Billing
      </div>
      <div
        onClick={changeInterval('monthly')}
        className={clsx(styles.right, {
          [styles['active-interval']]: priceInterval === 'monthly',
        })}
      >
        Monthly Billing
      </div>
    </div>
  );
};

export default NewPricingIntervalSelector;
