import { toaster } from 'evergreen-ui';
import * as React from 'react';
import { DateTime } from 'luxon';
import { IAcceptedCurrencies, ITicketCategory } from '../interface/ticket';

export async function copyTextToClipboard(
  text?: string,
  inputId = 'text-clipboard-input',
  toastMessage?: string
) {
  const copyText = document.getElementById(inputId) as HTMLInputElement;
  if (copyText && !text) {
    // @ts-ignore
    copyText.select();
    // @ts-ignore
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // @ts-ignore
    await navigator.clipboard.writeText(copyText.value);
    document.execCommand('copy');
    // @ts-ignore
    toaster.success('Success', {
      description: toastMessage ?? `Copied ${copyText.value} to clipboard.`,
    });
  } else if (text) {
    await navigator.clipboard.writeText(text);
    toaster.success('Success', {
      description: toastMessage ?? 'Hi your sharable url has been copied to clipboard',
    });
  }
}

export function getEnv(variable: string, defaultValue: string = ''): string {
  if (variable in process.env && process.env[variable]) {
    return process.env[variable] as string;
  }
  return process.env[`REACT_APP_${variable}`] || defaultValue;
}

export function redactPropertyIfDefined(
  obj: Record<string, unknown>,
  key: string
): '[REDACTED]' | undefined {
  return obj[key] ? '[REDACTED]' : undefined;
}

export const redactPayloadProp = (
  payload: { [key: string]: any },
  redact: string[] = [],
  shouldDelete = false
) => {
  if (Array.isArray(payload)) {
    for (const data of payload) {
      payload[data] = redactPayloadProp(payload[data], redact);
    }
  } else if (typeof payload === 'object') {
    for (const key in payload) {
      if (redact.includes(key)) {
        if (!shouldDelete) {
          payload[key] = '[REDACTED]';
        } else {
          Reflect.deleteProperty(payload, key);
        }
      } else {
        if (!Object.isFrozen(payload[key])) {
          payload[key] = redactPayloadProp(payload[key], redact);
        }
      }
    }
  }
  return payload;
};

type IToasterCall = {
  type: 'success' | 'danger' | 'notify' | 'warning';
  message?: string;
  id?: string;
  settings?: {
    description?: React.ReactNode;
    duration?: number;
    id?: string;
    hasCloseButton?: boolean;
  };
};

export const toasterCall = ({ type, message, settings, id }: IToasterCall) => {
  const toasterSettings = settings ?? {
    description: message,
    id: id ?? 'forbidden-action',
    duration: 30,
  };
  toasterSettings.description = settings?.description ?? message;
  if (type === 'success') {
    toaster.success('Success ', toasterSettings);
  }
  if (type === 'danger') {
    toaster.danger('Error ✘', toasterSettings);
  }
  if (type === 'notify') {
    toaster.notify('Notice', toasterSettings);
  }
  if (type === 'warning') {
    toaster.warning('Warning', toasterSettings);
  }
};

export const checkIfInputNumber = (value: string) => {
  if (isNaN(Number(value)) && value !== '') {
    return false;
  } else {
    return true;
  }
};

export const leadingZeros = (value: number, numberOfLeadingZero?: number) => {
  return String(Math.ceil(value)).padStart(numberOfLeadingZero ?? 2, '0');
};

export const getDateOrTimeObject = (date?: string, time?: boolean) => {
  const formatedDate = DateTime.fromISO(date ?? '').toObject();
  if (time) {
    return date
      ? {
          hour: formatedDate.hour,
          minute: formatedDate.minute,
          second: formatedDate.second,
        }
      : {
          hour: undefined,
          minute: undefined,
          second: undefined,
        };
  }
  return date ? formatedDate : DateTime.now().toObject();
};

export const getCurrencyIcon = (currency: IAcceptedCurrencies) => {
  switch (currency) {
    case 'NGN':
      return '₦';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'ZAR':
      return 'R';
    case 'KES':
      return 'KSh';
    case 'GHS':
      return '₵';
    case 'XOF':
      return 'CFA';
    default:
      return '';
  }
};

export const sumFromArray = (arr: any[], keyToReduce?: string, initialValue?: number) => {
  return arr.reduce((prev, current) => {
    return keyToReduce ? prev + Number(current[keyToReduce]) : prev + Number(current);
  }, initialValue ?? 0);
};

export const ticketTableTypeAndPriceFormat = (categories: ITicketCategory[]) => {
  const arr: { type: string; price: string; currency: IAcceptedCurrencies }[] = [];

  categories.forEach((category) => {
    arr.push({ type: category.type, price: category.price, currency: category.currency });
  });

  return arr;
};

export const calculatePrice = (
  price: number
): {
  monthly: number;
  yearly: number;
} => {
  return {
    monthly: price,
    yearly: price * 11,
  };
};

export const randomImage = (w: number | null = null, h: number | null = null) =>
  `https://source.unsplash.com/random?query=event,concert,people,artist,gospel,music,${Math.random()
    .toString()
    .replace('.', '')}${w ? '&w=' + w : ''}${h ? '&h=' + h : ''}&orientation=landscape&`;

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const canShowFullscreen = () => {
  // @ts-ignore
  return (
    document.fullscreenEnabled ||
    // @ts-ignore
    document.webkitFullscreenEnabled ||
    // @ts-ignore
    document.mozFullScreenEnabled ||
    // @ts-ignore
    document.msFullscreenEnabled
  );
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.mozCancelFullScreen) {
    // @ts-ignore
    document.mozCancelFullScreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.msExitFullscreen) {
    // @ts-ignore
    document.msExitFullscreen();
  }
};

export const openFullscreen = (element: any = document.body) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const redirectToContactsPage = () => {
  window.open(`${getEnv('REACT_APP_CONTACTS_PAGE_URL')}`, '__blank');
};

export const getCurrentPage = (cacheOnly = false) => {
  const path = window.location.pathname;
  const search = window.location.search;
  let completePath = path + search;
  const localPath = localStorage.getItem('latestUrl');
  if (!completePath.startsWith('/sso/auth')) {
    localStorage.setItem('latestUrl', completePath);
  }
  return cacheOnly ? localPath : completePath;
};

export function encodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      // @ts-ignore
      return String.fromCharCode('0x' + p1);
    })
  );
}

export const sanitizeCountry = (country: string) => {
  const replacer = [{ from: 'ô', to: 'o' }];
  return replacer.reduce((acc, curr) => {
    return acc.replace(curr.from, curr.to);
  }, country);
};

export const handeExternalSubmit = (
  { label, value }: any,
  object = {},
  array = [],
  customFnc = (_: any) => {}
) => {
  if (customFnc) {
    const cf: any = {
      ...object,
      ...(label && value ? { [label]: value } : {}),
    };
    const resp = array?.reduce((acc: any, cur: any) => {
      return [
        ...acc,
        {
          ...cur,
          value: cf[cur.label],
        },
      ];
    }, []);
    customFnc(resp);
  }
};

export const getFieldType = (type: string | any) => {
  const field = ((typeof type === 'string' ? type : type?.value) || 'text').toLowerCase();
  switch (field) {
    case 'checkbox':
      return 'checkbox';
    case 'number':
      return 'number';
    case 'email':
      return 'email';
    case 'password':
      return 'password';
    case 'text':
    default:
      return 'text';
  }
};

export const sortArray = (arr: any[], key: string, order: 'asc' | 'desc' = 'asc') => {
  return arr.sort((a: any, b: any) => {
    if (a[key] < b[key]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
};
