import { IUser } from '../../interface/user';
import $http from '../../services/api';
import { hasUserPaid, toasterCall } from '../../utils';
type prop = {
  isStarter: boolean;
  isBusiness: boolean;
  isEnterprise: boolean;
  user: IUser;
  planInterval: string;
};

export const pricingCardPlanConditions = ({
  isBusiness,
  isEnterprise,
  isStarter,
  planInterval,
  user,
}: prop) => {
  let onEssentialPlan = false;
  let downgradeFromBusinessToEssential = false;
  let downgradeFromEnterpriseToEssential = false;
  let onBusinessPlan = false;
  let onEnterprisePlan = false;
  let downgradeFromEnterpriseToBusiness = false;
  let sameInterval = user.meta.paidPlanInterval === planInterval;

  const { isOnStarterPlan, isOnPremiumPlan } = hasUserPaid(user);

  if (isOnStarterPlan) {
    onEssentialPlan = true;
  }

  if (isOnPremiumPlan) {
    onBusinessPlan = true;
  }

  if (user.is_enterprise) {
    onEnterprisePlan = true;
  }

  if (isStarter) {
    if (isOnPremiumPlan && user?.meta?.downgradingTo?.isStarter) {
      downgradeFromBusinessToEssential = true;
    }
    if (user?.is_enterprise && user?.meta?.downgradingTo?.isStarter) {
      downgradeFromEnterpriseToEssential = true;
    }
  }

  if (isBusiness) {
    if (user?.is_business) {
      if (user?.meta?.downgradingTo?.isStarter) {
        downgradeFromBusinessToEssential = true;
      }
    }
    if (user?.is_enterprise && user?.meta?.downgradingTo?.isBusiness) {
      downgradeFromEnterpriseToBusiness = true;
    }
  }

  if (isEnterprise) {
    if (user?.is_enterprise) {
      if (user?.meta?.downgradingTo?.isBusiness) {
        downgradeFromEnterpriseToBusiness = true;
      }
      if (user?.meta?.downgradingTo?.isStarter) {
        downgradeFromEnterpriseToEssential = true;
      }
    }
  }
  return {
    onEssentialPlan,
    downgradeFromBusinessToEssential,
    downgradeFromEnterpriseToEssential,
    onBusinessPlan,
    onEnterprisePlan,
    downgradeFromEnterpriseToBusiness,
    sameInterval,
  };
};

export const activateDowngrade = async (
  setLoading: any,
  updateUserMeta: any,
  setIsShown: any,
  activate?: boolean
) => {
  setLoading(true);
  try {
    const { data }: any = await $http.get(
      `plans/modify?type=${activate ? 'activate' : 'downgrade'}`
    );
    updateUserMeta(data);
    setIsShown(false);
    toasterCall({
      type: 'success',
      message: activate
        ? 'You have successfully activated your essential.'
        : 'You have successfully downgraded to essential.',
    });
  } catch (error: any) {
    console.error(error.response);
  } finally {
    setLoading(false);
  }
};
