import React from 'react';
import {
  copyTextToClipboard,
  hasUserPaid,
  LOCAL_STORAGE_TOKEN_EXPIRE,
  LOCAL_STORAGE_TOKEN_KEY,
  logout,
  MAXIMUM_ACTIVITIES_FOR_STARTER,
  MAXIMUM_EVENTS_FOR_STARTER,
  MAXIMUM_TICKETS_FOR_STARTER,
  redactPayloadProp,
} from '../utils';
import { localForage } from '../store/persist';
import { toaster } from 'evergreen-ui';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { userAtomState, userSubscriptionAtomState, passwordStillValidAtom } from '../store/atoms';
import { DateTime } from 'luxon';
import { getInfo, getSubscriptionById } from '../services/subcription';
import { ISubscription } from '../interface/subscription';
import { IUseUser } from '../interface/user';
import { maxAccountNumber } from '../utils/payment';

export function useUser(): IUseUser {
  const [user, saveUserInfo] = useRecoilState(userAtomState);
  const [subscription, setSubscription] = useRecoilState<{} | ISubscription>(
    userSubscriptionAtomState
  );
  const [, resetPasswordStillValid] = useRecoilState(passwordStillValidAtom);
  const userLogout = useRecoilCallback(() => async () => {
    resetPasswordStillValid({});
    await localForage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRE);
    await localForage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    saveUserInfo({});
    setSubscription({});
    await logout();
  });

  const reloadUser = React.useCallback(async () => {
    const res = await getInfo();
    if (res) {
      saveUserInfo(res);
    }
    const sub = await getSubscriptionById(user?.meta?.subscription?.plan);
    if (sub) {
      setSubscription({ ...sub, userId: user?.id } || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    user,
    (data: { [key: string]: any }) => {
      saveUserInfo(redactPayloadProp(data, ['token', 'password'], true));
    },
    {
      ...hasUserPaid(user),
      subscription,
      lessThan60Days: user.created_at && DateTime.fromISO(user?.created_at).diffNow().days < 60,
      updateSubscription: (data: { plan: string; planInterval: string }) => {
        saveUserInfo({
          ...user,
          meta: {
            ...user.meta,
            subscription: data,
          },
        });
      },
      updateUserMeta: (data: { [key: string]: any }) => {
        saveUserInfo({
          ...user,
          meta: {
            ...user.meta,
            ...data,
          },
        });
      },
      updateUserDataNotMeta: (data: { [key: string]: any }) => {
        saveUserInfo({
          ...user,
          ...data,
          meta: {
            ...user.meta,
          },
        });
      },
      reloadUser,
      copyUserId: (message?: string, transform: (value?: any) => string = (value) => value) => {
        return copyTextToClipboard(
          transform(user.id),
          'user-id-text-area-input',
          message ?? 'User ID copied to clipboard'
        );
      },
      maxUserAccountAllowed: maxAccountNumber(user),
      logout: userLogout,
      hasReachedEventLimit:
        hasUserPaid(user).isOnStarterPlan && user.events >= MAXIMUM_EVENTS_FOR_STARTER,
      hasReachedActivityLimit:
        hasUserPaid(user).isOnStarterPlan && user.activities >= MAXIMUM_ACTIVITIES_FOR_STARTER,
      hasReachedTicketLimit:
        hasUserPaid(user).isOnStarterPlan && user.tickets >= MAXIMUM_TICKETS_FOR_STARTER,
    },
  ];
}

export const useAccessToken = () => {
  const [data, setData] = React.useState<any>(null);
  const [expires, setExpires] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const value = await localForage.getItem(LOCAL_STORAGE_TOKEN_KEY);
      setData(value);

      const expiresETA = await localForage.getItem(LOCAL_STORAGE_TOKEN_EXPIRE);
      setExpires(expiresETA);
      setLoading(false);
    })();
  }, []);
  const save = React.useCallback(async (token: string, expires?: string) => {
    await localForage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    await localForage.setItem(LOCAL_STORAGE_TOKEN_EXPIRE, expires);
  }, []);
  return [data, save, expires, loading];
};

export const useIsSessionExpired = () => {
  const [data, , expiresIn, loading] = useAccessToken();
  React.useEffect(() => {
    if (!loading && loading !== null) {
      const expired = new Date(expiresIn).getTime();
      if ((!data && !expiresIn) || (expiresIn && expired < Date.now())) {
        toaster.danger('Error ✘', {
          description: 'Your session has expired. Please, login.',
          duration: 5000,
        });
        setTimeout(() => logout(), 2000);
      }
    }
  }, [data, expiresIn, loading]);
  return null;
};
