import React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from '../component/ErrorBoundary';
import SuspenseLoader from '../component/SuspenseLoader';
import { getCurrentPage } from '../utils';
import PaidOnlyRoute from '../component/PaidOnlyRoute';

const SimpleSuspense = (Component: React.FunctionComponent, props?: any) => {
  if (!props?.disableSavePath) {
    getCurrentPage();
  }

  return (
    <Suspense fallback={<SuspenseLoader fullscreen />}>
      <ErrorBoundary>
        <PaidOnlyRoute>
          <Component {...props} />
        </PaidOnlyRoute>
      </ErrorBoundary>
    </Suspense>
  );
};

export default SimpleSuspense;
