export function getImageFromDara(picture: any) {
  if (picture && picture.format && picture.data) {
    return `data:${picture.format};base64,${picture.data.toString('base64')}`;
  }
  return '';
}

export function convertDataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getImageAlt = (img: string | any) => {
  if (typeof img === 'string' || !img) return img;
  return img?.alt_description || img?.alt;
};
export const getImageUrl = (
  img: string | any,
  quality: 'small' | 'regular' | 'raw' | 'full' = 'small'
) => {
  if (typeof img === 'string' || !img) return img;
  return typeof img?.url === 'string' ? img?.url : img?.urls?.[quality] ?? img?.src;
};
