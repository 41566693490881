import React from 'react';
import './styles.scss';
import { Pane, Spinner } from 'evergreen-ui';
import clsx from 'clsx';

export interface ISuspenseLoader {
  fullscreen?: boolean;
  children?: React.ReactNode;
  className?: string;
  size?: number;
}

const SuspenseLoader: React.FunctionComponent<ISuspenseLoader> = ({
  fullscreen = false,
  children,
  className,
  size = 40,
}) => {
  return (
    <Pane className={clsx('suspenseLoader', { fullscreen }, className)}>
      <>
        <Spinner color="red" size={size} />
        {children}
      </>
    </Pane>
  );
};

export default SuspenseLoader;
