import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import Main from './container/main';

export default class App extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <Main />;
      </BrowserRouter>
    );
  }
}
