import { Button } from 'evergreen-ui';
import React from 'react';
import clsx from 'clsx';
import './styles.scss';
import { ReactComponent as ArrowBack } from '../../assets/svgs/arrow-back.svg';
import { useNavigate } from 'react-router-dom';

export interface ICustomButton {
  primary?: boolean;
  invert?: boolean;
  success?: boolean;
  danger?: boolean;
  inactive?: boolean;
  noBorder?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  height?: number;
  iconAfter?: any;
  iconBefore?: any;
  children?: any;
  width?: string;
  className?: string;
  color?: string;
  type?: 'button' | 'submit' | 'reset';
  style?: { [key: string]: any };
}

const CustomButton: React.FunctionComponent<ICustomButton> = ({
  primary,
  loading,
  disabled,
  onClick,
  label,
  size = 'large',
  height,
  iconAfter,
  iconBefore,
  success,
  children,
  fullWidth,
  width,
  className,
  noBorder,
  color,
  danger,
  invert,
  style,
  type,
  inactive,
}) => {
  return !children && !label ? null : (
    <Button
      onClick={onClick}
      isLoading={loading}
      disabled={disabled}
      className={clsx(
        'custom-button',
        { invert },
        { primary },
        { success },
        { noBorder },
        { danger },
        { inactive },
        { 'full-width': fullWidth },
        className
      )}
      size={size}
      height={height}
      iconAfter={iconAfter}
      iconBefore={iconBefore}
      width={width ?? '8.75rem'}
      style={{ color: color ?? '', ...style }}
      type={type}
    >
      {label || children}
    </Button>
  );
};

export default CustomButton;

export const BackButton = () => {
  const location = useNavigate();
  const handleClick = () => {
    location(-1);
  };
  return (
    <Button
      className={'back-button'}
      onClick={handleClick}
      iconBefore={<ArrowBack className="arrow-back" />}
    >
      Back
    </Button>
  );
};
