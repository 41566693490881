import React, { Component } from 'react';
import CustomButton from '../CustomButton';
import styles from './ComingSoon.module.scss';

/**
 * Error boundary catches errors anywhere in their child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 *
 * Use ErrorBoundary to prevent White Screen Of Death (WSOD).
 *
 * Example:
 *
 * <ErrorBoundary>
 *   <Widget />
 * </ErrorBoundary>
 */
export class ErrorBoundary extends Component<{ children?: React.ReactNode }> {
  // inital state
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.log(this);
    // log error messages
    console.error('ErrorBoundary: ', error.toString());
    console.error('ErrorBoundary', info);
  }

  render() {
    const { error } = this.state;
    if (error) {
      // Error path
      return (
        <div className={styles.soon}>
          <h2 className={styles.h2}>An unexpected error occurred.</h2>
          <p className={styles.p}>Please try again.</p>
          <CustomButton primary onClick={() => window.location.reload()}>
            Try Again
          </CustomButton>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
