import styles from './PricingCard.module.scss';
import { ReactComponent as Checkmark } from '../../assets/svgs/check-mark.svg';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton';
import { IUser } from '../../interface/user';
import { BillingRoute } from '../../navigation/routes';
import { getCurrencyIcon, redirectToContactsPage } from '../../utils/general';
import React, { useState } from 'react';
import { activateDowngrade, pricingCardPlanConditions } from './config';

type IPricingCard = {
  title: string;
  description?: string;
  price?: string | number;
  pricingRate?: string;
  listHeader?: string;
  features?: string[];
  isStarter: boolean;
  isBusiness: boolean;
  isEnterprise: boolean;
  subscription?: any;
  user: IUser;
  updateSubscription: (data: { plan: string; planInterval: string }) => void;
  planInterval: string;
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserMeta: (data: { [key: string]: any }) => void;
};

const PricingCard = ({
  title,
  description,
  price,
  listHeader,
  features,
  isStarter,
  isBusiness,
  isEnterprise,
  subscription,
  user,
  updateSubscription,
  planInterval,
  setIsShown,
  updateUserMeta,
}: IPricingCard) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    downgradeFromBusinessToEssential,
    downgradeFromEnterpriseToBusiness,
    downgradeFromEnterpriseToEssential,
    onBusinessPlan,
    onEnterprisePlan,
    onEssentialPlan,
    sameInterval,
  } = pricingCardPlanConditions({ isBusiness, isStarter, isEnterprise, planInterval, user });

  const handleClick = async () => {
    if (
      isBusiness &&
      (onEssentialPlan ||
        (onBusinessPlan && !sameInterval) ||
        (downgradeFromBusinessToEssential && !sameInterval))
    ) {
      updateSubscription({
        plan: subscription.id,
        planInterval: planInterval,
      });
      navigate('/' + BillingRoute);
    }

    if (isBusiness && downgradeFromBusinessToEssential && sameInterval) {
      await activateDowngrade(setLoading, updateUserMeta, setIsShown, true);
    }

    if (isStarter && onBusinessPlan) {
      await activateDowngrade(setLoading, updateUserMeta, setIsShown, false);
    }

    if (isEnterprise) {
      redirectToContactsPage();
    }
  };

  const disableButton = () => {
    if (loading) return true;
    if (isStarter) {
      if (onEssentialPlan) return true;
      if (downgradeFromBusinessToEssential || downgradeFromEnterpriseToEssential) return true;
      return false;
    }
    if (isBusiness) {
      if (onBusinessPlan) {
        if (downgradeFromBusinessToEssential) {
          return false;
        }
        if (downgradeFromEnterpriseToBusiness) {
          return false;
        }
        return sameInterval;
      }
      return false;
    }
    if (isEnterprise) {
      if (onEnterprisePlan) {
        if (downgradeFromEnterpriseToEssential || downgradeFromEnterpriseToBusiness) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  };

  const buttonTexts = () => {
    if (isStarter) {
      if (onEssentialPlan) return 'Current Plan';
      if (downgradeFromBusinessToEssential || downgradeFromEnterpriseToEssential)
        return 'Downgrading to Essential';
      return 'Downgrade';
    }
    if (isBusiness) {
      if (onBusinessPlan) {
        if (downgradeFromBusinessToEssential) {
          if (sameInterval) {
            return 'Re-activate Plan';
          } else {
            return `Upgrade to ${planInterval}`;
          }
        }
        if (downgradeFromEnterpriseToBusiness) {
          return 'Downgrading to Pro Business';
        }
        if (sameInterval) {
          return 'Current Plan';
        } else {
          return `Upgrade to ${planInterval}`;
        }
      }
      return 'Upgrade';
    }
    if (isEnterprise) {
      if (onEnterprisePlan) {
        if (downgradeFromEnterpriseToEssential || downgradeFromEnterpriseToBusiness) {
          return 'Contact Sales';
        }
        return 'Current Plan';
      }
      return 'Contact Sales';
    }
    return 'Upgrade';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.children}>
        <div
          className={clsx(styles.header, {
            [styles.starter]: isStarter,
            [styles.business]: isBusiness,
            [styles.enterprise]: isEnterprise,
          })}
        >
          <h4 className={styles.category}>{title}</h4>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={clsx(styles.content)}>
          <div className={styles['price-section']}>
            <p
              className={clsx(styles.price, {
                [styles['enterprise-price']]: isEnterprise,
              })}
            >
              {price && price < 0
                ? 'Contact support team for pricing'
                : `${getCurrencyIcon(subscription.currency)}${price} ${subscription.currency}`}
            </p>
          </div>

          <div className={styles['items-list']}>
            <h5 className={styles.h5}>{listHeader}</h5>
            <div>
              {features?.map((item, i) => (
                <div className={styles['flex-list']} key={i}>
                  <Checkmark className={styles.svg} />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['btn-container']}>
        <CustomButton
          primary={!isEnterprise}
          loading={loading}
          className={clsx(styles.btn, {
            [styles['enterprise-button']]: isEnterprise,
          })}
          onClick={handleClick}
          disabled={disableButton()}
        >
          {buttonTexts()}
        </CustomButton>
      </div>
    </div>
  );
};

export default PricingCard;
