export function Title({ children }: { children: string }) {
  document.title = children;
  return null;
}

/**
 * Meta component
 * @fileoverview add meta information to the document head <Meta> {children} </Meta>
 * @param children
 * @constructor
 */
export function Meta({ children }: { children: any[] }) {
  children.forEach((child) => {
    const { name, content } = child;
    const meta = document.createElement('meta');
    meta.setAttribute('name', name);
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  });
  return null;
}
export default Title;

export const updateCanonical = (pageUrl?: string) => {
  const url = pageUrl ?? window.location.href;
  const canonical = document.querySelector('link[rel="canonical"]');
  if (canonical) {
    canonical.setAttribute('href', url);
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    document.head.appendChild(link);
  }
};
