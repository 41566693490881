import { IPaymentBankPayload, IPaymentInterval, IPaymentOptions } from '../interface/payment';
import $http from './api';
import { ITicketCountPayload } from '../interface/ticket';

export const getPaymentCredentials = async ({
  subscriptionId,
  paymentProvider,
  interval,
}: {
  subscriptionId: string;
  paymentProvider: string;
  interval: IPaymentInterval;
}) => {
  try {
    const { data } = await $http.post(`payment`, {
      subscriptionId,
      paymentProvider,
      interval,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
export const getTicketPaymentCredentials = async (payload: {
  email: string;
  ticketsPayload: ITicketCountPayload[];
  total: number;
  paymentProvider: string;
  phoneNumber: string;
  eventId: string;
  name: string;
}) => {
  const { data } = await $http.post(`pub/payment/ticket`, payload);
  return data;
};

export const addPaymentTransaction = async ({
  internalPlanId,
  provider,
  uniqueTransactionId,
}: {
  internalPlanId: string;
  provider: string;
  uniqueTransactionId: string;
}) => {
  try {
    const { data } = await $http.post(`payment/transaction`, {
      internalPlanId,
      provider,
      uniqueTransactionId,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const supportedPayStackCountries = async (provider: IPaymentOptions = 'paystack') => {
  try {
    const { data } = await $http.get(`/payout/countries`);
    return data?.data?.map((country: any) => ({
      activeForDashboardOnboarding: country.active_for_dashboard_onboarding,
      callingCode: country.calling_code,
      defaultCurrencyCode: country.default_currency_code,
      id: country.id,
      integrationDefaults: country.integration_defaults,
      isoCode: country.iso_code,
      name: country.name,
      pilotMode: country.pilot_mode,
      currencies: Object.keys(country?.relationships?.currency?.supported_currencies),
    }));
  } catch (error) {
    return null;
  }
};

export const supportedPayStackBanks = async ({
  provider = 'paystack',
  currency,
  country,
}: IPaymentBankPayload) => {
  try {
    const { data } = await $http.get(
      `payout/banks?provider=${provider}&currency=${currency}&country=${country}`
    );
    return {
      ...(data || {}),
      data: data?.data?.filter((bank: any) => bank.active && !bank.is_deleted),
    };
  } catch (error) {
    return null;
  }
};

export const getCountries = async (type: IPaymentOptions = 'paystack') => {
  switch (type) {
    case 'paystack':
      return supportedPayStackCountries();
    default:
      return [];
  }
};
