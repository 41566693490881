import { atom, selectorFamily } from 'recoil';
import persistAtom from './persist';
import {
  billingInfoAtomStateInitStae,
  contactFormAtomStateInitState,
  dashboardStatistic,
  sideNavAtomStateInitState,
  ticketFormAtomStateInitState,
} from './initialState';
import { IPaymentInfoState } from '../interface/paymentInfoState';
import { IEvent, IEventsActiveUpcoming } from '../interface/events';
import { getCountries, supportedPayStackBanks } from '../services/payment';
import {
  IPaymentBankPayload,
  IPaymentOptions,
  IPaymentTransactionResponse,
  IPayoutTransaction,
} from '../interface/payment';
import Pusher from 'pusher-js';
import { ITicket } from '../interface/ticket';
import { IStageTime } from '../interface/stageTime';

export const testingAtomState = atom({
  key: 'testingAtomState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
  effects_UNSTABLE: [persistAtom], // effects (aka subscriptions)
});

export const sideNavAtomState = atom({
  key: 'sideNavAtomState',
  default: sideNavAtomStateInitState(),
  effects_UNSTABLE: [persistAtom],
});

export const toggleSideNavAtomState = atom({
  key: 'toggleSideNavAtomState',
  default: false,
});

export const paymentInfoAtomState = atom({
  key: 'paymentInfoAtomState',
  default: 'billing-info' as IPaymentInfoState,
});

export const userAtomState = atom({
  key: 'userAtomState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const userSubscriptionAtomState = atom({
  key: 'userSubscriptionAtomState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const billingInfoAtomState = atom({
  key: 'billingInfoAtomState',
  default: billingInfoAtomStateInitStae(),
});

export const contactFormAtomState = atom({
  key: 'contactFormAtomState',
  default: contactFormAtomStateInitState(),
});

export const ticketFormAtomState = atom({
  key: 'ticketFormAtomState',
  default: ticketFormAtomStateInitState(),
});

export const statisticAtomState = atom({
  key: 'statisticAtomState',
  default: dashboardStatistic(),
  effects_UNSTABLE: [],
});

export const unsplashAtomState = atom({
  key: 'unsplashAtomState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const unsplashAtomExpiresState = atom({
  key: 'unsplashAtomExpiresState',
  default: new Date().toISOString(),
  effects_UNSTABLE: [persistAtom],
});

export const completeOrderAtomState = atom({
  key: 'completeOrderAtomState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const ticketPaymentAtomState = atom({
  key: 'ticketPaymentAtomState',
  default: {
    loading: true,
  },
  effects_UNSTABLE: [persistAtom],
});

export const subscriptionPlansAtomState = atom({
  key: 'subscriptionPlansAtomState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const asideIsShownScreenAtomState = atom({
  key: 'asideIsShownScreen',
  default: true,
});

export const createStageTimeModalAtomState = atom({
  key: 'createStageTimeModalAtomState',
  default: {
    show: false,
    refresh: null as any | undefined,
  },
});

export const activeEventsAtomState = atom({
  key: 'activeEventsAtomState',
  default: [] as IEventsActiveUpcoming[],
});

export const upcomngEventsAtomState = atom({
  key: 'upcomngEventsAtomState',
  default: [] as IEventsActiveUpcoming[],
});

export const supportedBanksAtomState = atom({
  key: 'supportedBanksAtomState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const supportedBanks = selectorFamily({
  key: 'supportedBanksFamily',
  get:
    ({ key, payload }: { key: string; payload?: IPaymentBankPayload }) =>
    async ({ get }) => {
      const banks = get(supportedBanksAtomState);
      if (banks[key]) {
        return banks[key];
      }
      if (!payload) return [];
      const { data } = await supportedPayStackBanks(payload);
      return data?.data ?? data ?? [];
    },
});

export const supportedCountriesAtomState = atom({
  key: 'supportedCountriesAtomState',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const supportedCountries = selectorFamily({
  key: 'supportedCountriesFamily',
  get:
    (provider: IPaymentOptions) =>
    async ({ get }) => {
      const countries = get(supportedCountriesAtomState);
      if (countries[provider] && countries[provider].length > 0) {
        return countries[provider];
      }
      if (!provider) return [];
      const data = await getCountries(provider);
      return data?.data ?? data ?? [];
    },
});

export const showViewTransactionDetailsAtom = atom({
  key: 'showViewTransactionDetailsAtom',
  default: true,
});

export const passwordStillValidAtom = atom({
  key: 'passwordStillValidAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const pusherAppAtom = atom({
  key: 'pusherAppAtom',
  default: {
    connected: false,
    socketId: '',
    isSignedIn: false,
    userData: {},
    pusher: null as Pusher | null,
  },
});

export const refreshPayoutTableAtomState = atom({
  key: 'refreshPayoutTableAtomState',
  default: {
    show: false,
    refresh: null as any | undefined,
  },
});

export const eventsPageTableAtomState = atom({
  key: 'eventsPageTableAtomState',
  default: [] as IEvent[],
});

export const ticketSalesPageTableAtomState = atom({
  key: 'ticketSalesPageTableAtomState',
  default: [] as any[],
});

export const ticketPageTableAtomState = atom({
  key: 'ticketPageTableAtomState',
  default: [] as (IEvent & Omit<ITicket, 'meta'>)[],
});

export const viewTimerPageTableAtomState = atom({
  key: 'viewTimerPageTableAtomState',
  default: [] as IStageTime[],
});

export const payoutPageTableAtomState = atom({
  key: 'payoutPageTableAtomState',
  default: [] as IPayoutTransaction[],
});

export const billingtPageTableAtomState = atom({
  key: 'billingtPageTableAtomState',
  default: [] as IPaymentTransactionResponse[],
});

export const dashbordEventtPageTableAtomState = atom({
  key: 'dashbordEventtPageTableAtomState',
  default: [] as IEvent[],
});
