import axios, { AxiosResponse } from 'axios';
import { getEnv, LOCAL_STORAGE_TOKEN_KEY, logout } from '../utils';
import { localForage } from '../store/persist';
import { getIPDataInfo } from './general';

export let baseURL = getEnv('API_URL');
export const notificationBaseURL = getEnv('NOTIFICATION_API_URL');

export const $http = axios.create({
  baseURL,
});

export const $httpNotification = axios.create({
  baseURL: notificationBaseURL + '/v1/',
});

$http.interceptors.request.use(async (config) => {
  const token = await localForage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (typeof token === 'string') {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${token}`,
      'XX-Client': await getIPDataInfo(),
    };
  }
  console.log(config.data);

  if (config.method !== 'get' && config.method !== 'delete') {
    const IPA = await getIPDataInfo();
    if (config.data?.append) {
      config.data.append('IPA', IPA);
    } else {
      config.data.IPA = IPA;
    }
  }
  if (config.method !== 'get') {
    config.data = {
      ...(config.data || {}),
      IPA: await getIPDataInfo(),
    };
  }
  console.log(config);
  return config;
});

$http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (axiosResponse) => {
    const resp = axiosResponse.response;
    if (
      resp?.status === 401 ||
      (resp?.data && resp?.data?.error && resp?.data?.error?.redirectTo)
    ) {
      setTimeout(() => logout(), 2000);
    }
    throw axiosResponse;
  }
);

$httpNotification.interceptors.request.use(async (config) => {
  const token = await localForage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (typeof token === 'string') {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

$httpNotification.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (axiosResponse) => {
    const resp = axiosResponse.response;
    if (resp.status === 401 || (resp.data && resp.data.error && resp.data.error.redirectTo)) {
      setTimeout(() => logout(), 2000);
    }
    throw axiosResponse;
  }
);

export interface MetaData {
  current_page: number;
  first_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export default $http;
