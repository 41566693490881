import React, { useEffect, useState } from 'react';
import { calculatePrice } from '../../utils';
import NewPricingIntervalSelector from '../NewPricingIntervalSelector';
import PricingCard from '../PricingCard';
import SuspenseLoader from '../SuspenseLoader';
import styles from './style.module.scss';
import { IPaymentInterval, IPaymentSubcription } from '../../interface/payment';
import { useRecoilState } from 'recoil';
import { subscriptionPlansAtomState } from '../../store/atoms';
import { DateTime } from 'luxon';
import { Dialog, Pane } from 'evergreen-ui';
import { useUser } from '../../hooks/useUser';
import $http from '../../services/api';

interface ISubscriptionPlanOptions {
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  isShown: boolean;
}

const SubscriptionPlanOptions: React.FunctionComponent<ISubscriptionPlanOptions> = ({
  setIsShown,
  isShown,
}) => {
  const [user, , { updateSubscription, updateUserMeta }] = useUser();
  const [priceInterval, setPriceInterval] = useState<IPaymentInterval>(
    user?.meta?.paidPlanInterval ?? 'monthly'
  );

  const [subscriptions, setSubscriptions] = useRecoilState<IPaymentSubcription[]>(
    subscriptionPlansAtomState
  );

  useEffect(() => {
    if (!isShown) return;
    if (subscriptions.length) return;

    (async () => {
      const { data } = await $http.get('pub/subscriptions');
      setSubscriptions(data.data);
    })();
  }, [isShown, setSubscriptions, subscriptions]);

  return (
    <Pane>
      <Dialog
        isShown={isShown}
        onCloseComplete={() => setIsShown(false)}
        hasHeader={false}
        hasFooter={false}
        topOffset={20}
        width="max-content"
      >
        <section>
          <div className={styles['pricing-interval']}>
            <NewPricingIntervalSelector
              priceInterval={priceInterval}
              setPriceInterval={setPriceInterval}
            />

            {user?.meta?.downgradingTo &&
              (user?.meta?.downgradingTo?.isBusiness || user?.meta?.downgradingTo?.isStarter) && (
                <div className={styles['is-downgrading']}>
                  You are currently on the {user.subscription_type} plan. You will be downgraded to
                  the {user?.meta?.downgradingTo?.isStarter ? 'Essentials' : 'Pro Business'} plan on{' '}
                  {DateTime.fromISO(user?.subscription_expired_at as any).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
                  .
                </div>
              )}
          </div>

          <div className={styles['card-container']}>
            {subscriptions.length > 0 ? (
              subscriptions.map((plan: IPaymentSubcription) => (
                <PricingCard
                  key={plan.id}
                  user={user}
                  title={plan.name}
                  description={plan.description}
                  price={calculatePrice(Number(plan.price))[priceInterval]}
                  listHeader={
                    plan.plan === 'free'
                      ? 'Benefits include:'
                      : plan.plan === 'pro'
                      ? 'All the benefits of Essentials, and:'
                      : 'All the benefits of Pro Business, and:'
                  }
                  isStarter={plan.name === 'Essentials'}
                  isBusiness={plan.name === 'Pro Business'}
                  isEnterprise={plan.name === 'Enterprise'}
                  planInterval={priceInterval}
                  subscription={{
                    ...plan,
                    features: JSON.parse(plan.features ?? '[]'),
                  }}
                  updateSubscription={updateSubscription}
                  features={JSON.parse(plan.features ?? '[]')}
                  setIsShown={setIsShown}
                  updateUserMeta={updateUserMeta}
                />
              ))
            ) : (
              <SuspenseLoader fullscreen />
            )}
          </div>
        </section>
      </Dialog>
    </Pane>
  );
};

export default SubscriptionPlanOptions;
