import React, { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from '../component/ScrollToTop';
import SuspenseLoader from '../component/SuspenseLoader';
import useCache from '../hooks/useCache';
import {
  AccountSecurityRoute,
  ActivitiesRoute,
  AppIntegrationsRoute,
  AuthSSORoute,
  BillingRoute,
  CompleteOrderRoute,
  DashboardRoute,
  EventsFormRoute,
  EventsRoute,
  ExampleRoute,
  GuestsFormRoute,
  GuestsProfileRoute,
  GuestsRoute,
  NotificationsDashboardRoute,
  NotificationsRoute,
  PaymentMethodsRoute,
  PaymentTransactionRoute,
  PayoutRoute,
  PayoutSettingsRoute,
  PayoutTransactionDetailsRoute,
  PayoutTransactionsRoute,
  POSPaymentRoute,
  PresenterModeRoute,
  SettingsRoute,
  StageTimeEventRoute,
  TicketsCreateRoute,
  TicketsRoute,
  ViewAttendanceTicketsRoute,
  ViewEventAttendanceRoute,
  ViewEventsRoute,
  ViewStageTimersRoute,
  ViewTicketSalesRoute,
  ViewTicketsRoute,
  ProfileSettingRoute,
  BillingSettingsRoute,
  TimerCountdownRoute,
  PaymentRequiredError,
  ViewEventAnalyticsRoute,
  DeveloperToolsRoute,
  ViewEventAttendanceRouteNew,
  ConfirmQRCodeRouteNew,
} from '../navigation/routes';
import SimpleSuspense from './SimpleSuspense';
import './_main.scss';
import useAnalytics from '../hooks/useAnalytics';
import { updateCanonical } from '../component/Title';
import { ConfirmQRCode } from '../screens/Attendance/ConfirmQRCode';

export interface IMain {}

const Main: React.FunctionComponent<IMain> = () => {
  useCache({ provider: 'paystack' });
  const DashboardScreen = lazy(() => import('../screens/Dashboard'));
  const Dashboard = lazy(() => import('../screens/Dashboard/Dashboard'));
  const Event = lazy(() => import('../screens/Events'));
  const SettingsLayout = lazy(() => import('../screens/Settings/Layout'));
  const ViewEvent = lazy(() => import('../screens/NewViewEventInformation'));
  const AccountSecuritySettings = lazy(
    () => import('../screens/Settings/AccoutSecuritySettings/index')
  );
  const DeveloperToolsSettings = lazy(
    () => import('../screens/Settings/DeveloperToolsSettings/index')
  );
  const ProfileSetting = lazy(() => import('../screens/ProfileSettingsScreen'));
  const SettingsBillingInfoScreen = lazy(() => import('../screens/Settings/SettingsBillingInfo'));
  const NotificationSettings = lazy(() => import('../screens/Settings/NotificationSettings'));
  const AccountIntegration = lazy(() => import('../screens/Settings/AccountIntegration'));
  const ActivityDashbord = lazy(() => import('../screens/Activities'));
  const PaymentSettings = lazy(() => import('../screens/PaymentSettings'));
  const Guests = lazy(() => import('../screens/Guests'));
  const AddGuests = lazy(() => import('../screens/Guests/AddGuests'));
  const GuestProfile = lazy(() => import('../screens/Guests/GuestProfile'));
  const Ticket = lazy(() => import('../screens/Tickets'));
  const TicketCreate = lazy(() => import('../screens/Tickets/CreateEdit'));
  const ViewTicket = lazy(() => import('../screens/Tickets/ViewTicket'));
  const PaymentTransaction = lazy(() => import('../screens/Billing/BillingReceipt'));
  const StageTimeEvents = lazy(() => import('../screens/StageTimeEvent'));
  const POSTable = lazy(() => import('../screens/PointOfSale/TakePayment'));
  const Attendance = lazy(() => import('../screens/Attendance'));
  const CompleteOrder = lazy(() => import('../screens/Attendance/CompleteOrder'));
  const ViewAttendanceTickets = lazy(() => import('../screens/Attendance/ViewAttendanceTickets'));
  const NotificationScreen = lazy(() => import('../screens/NotificationScreen'));
  const PresenterMode = lazy(() => import('../screens/PresenterMode'));
  const ViewTimers = lazy(() => import('../screens/ViewTimers'));
  const PayoutSettings = lazy(() => import('../screens/Settings/PayoutSettings'));
  const Payout = lazy(() => import('../screens/Payout'));
  const PayoutTransactions = lazy(() => import('../screens/PayoutTransaction'));
  const PayoutWithdrawals = lazy(() => import('../screens/PayoutWithdrawals'));
  const ViewTicketSales = lazy(() => import('../screens/TicketSales'));
  const PaymentInformation = lazy(() => import('../screens/Billing'));
  const AuthSSO = lazy(() => import('../screens/AuthSSO'));
  const CalendarExample = lazy(() => import('../Pages/CalendarExample'));
  const NewEventForm = lazy(() => import('../screens/Events/NewEventForm'));
  const StageTimeCountdown = lazy(() => import('../screens/TimerCountdown'));
  const PaymentError = lazy(() => import('../screens/Error/PaymentRequiredError'));
  const AttendanceAnalytics = lazy(() => import('../screens/Analytics/Attendance'));
  const ConfirmQRCode = lazy(() => import('../screens/Attendance/ConfirmQRCode'));
  const { track } = useAnalytics();
  const { pathname } = useLocation();

  React.useEffect(() => {
    track('Pageview', { page: pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateCanonical();
  }, [pathname]);
  return (
    <main className="main">
      <Suspense fallback={<SuspenseLoader fullscreen />}>
        <ScrollToTop />
        <Routes>
          <Route path={AuthSSORoute} element={SimpleSuspense(AuthSSO, { disableSavePath: true })} />

          <Route path={BillingRoute} element={SimpleSuspense(PaymentInformation)} />
          <Route path={TimerCountdownRoute + '/:id'} element={SimpleSuspense(StageTimeCountdown)} />
          <Route path={PaymentRequiredError} element={SimpleSuspense(PaymentError)} />
          <Route path={DashboardRoute} element={<DashboardScreen />}>
            <Route path={ConfirmQRCodeRouteNew} element={SimpleSuspense(ConfirmQRCode)} />
            <Route path={DashboardRoute} element={SimpleSuspense(Dashboard)} />
            <Route path={EventsRoute} element={SimpleSuspense(Event)} />
            <Route path={EventsFormRoute} element={SimpleSuspense(NewEventForm)} />
            <Route path={ActivitiesRoute} element={SimpleSuspense(ActivityDashbord)} />
            <Route path={GuestsRoute} element={SimpleSuspense(Guests)} />
            <Route path={GuestsFormRoute} element={SimpleSuspense(AddGuests)} />
            <Route path={GuestsProfileRoute + '/:id'} element={SimpleSuspense(GuestProfile)} />
            <Route path={ViewEventsRoute + '/:id'} element={SimpleSuspense(ViewEvent)} />
            <Route path={TicketsRoute} element={SimpleSuspense(Ticket)} />
            <Route path={TicketsCreateRoute} element={SimpleSuspense(TicketCreate)} />
            <Route path={ViewTicketsRoute + '/:id'} element={SimpleSuspense(ViewTicket)} />
            <Route path={POSPaymentRoute} element={SimpleSuspense(POSTable)} />
            <Route
              path={NotificationsDashboardRoute}
              element={SimpleSuspense(NotificationScreen)}
            />
            <Route path={ViewStageTimersRoute} element={SimpleSuspense(ViewTimers)} />
            <Route path={PayoutRoute} element={SimpleSuspense(Payout)} />
            <Route path={ViewTicketSalesRoute} element={SimpleSuspense(ViewTicketSales)} />
            <Route
              path={PayoutTransactionDetailsRoute + '/:id'}
              element={SimpleSuspense(PayoutTransactions)}
            />
            <Route path={PayoutTransactionsRoute} element={SimpleSuspense(PayoutWithdrawals)} />

            <Route path={PresenterModeRoute} element={SimpleSuspense(PresenterMode)} />
            <Route path={ViewEventAnalyticsRoute} element={SimpleSuspense(AttendanceAnalytics)} />

            {/* <Route
                path={AccountSecurityRoute}
                element={SimpleSuspense(AccountSecuritySettings)}
              /> */}
          </Route>
          <Route path={SettingsRoute} element={<SettingsLayout />}>
            <Route path={AccountSecurityRoute} element={SimpleSuspense(AccountSecuritySettings)} />
            <Route path={ProfileSettingRoute} element={SimpleSuspense(ProfileSetting)} />
            <Route
              path={BillingSettingsRoute}
              element={SimpleSuspense(SettingsBillingInfoScreen)}
            />
            <Route path={NotificationsRoute} element={SimpleSuspense(NotificationSettings)} />
            <Route path={AppIntegrationsRoute} element={SimpleSuspense(AccountIntegration)} />
            <Route path={PaymentMethodsRoute} element={SimpleSuspense(PaymentSettings)} />
            <Route path={PayoutSettingsRoute} element={SimpleSuspense(PayoutSettings)} />
            <Route path={DeveloperToolsRoute} element={SimpleSuspense(DeveloperToolsSettings)} />
          </Route>

          <Route path={ViewEventAttendanceRoute + '/:id'} element={SimpleSuspense(Attendance)} />

          <Route path={ViewEventAttendanceRoute} element={SimpleSuspense(Attendance)} />
          <Route path={ViewEventAttendanceRouteNew} element={SimpleSuspense(Attendance)} />

          <Route path={CompleteOrderRoute} element={SimpleSuspense(CompleteOrder)} />

          <Route
            path={ViewAttendanceTicketsRoute + '/:id'}
            element={SimpleSuspense(ViewAttendanceTickets)}
          />

          <Route path={StageTimeEventRoute + '/:id'} element={SimpleSuspense(StageTimeEvents)} />

          <Route
            path={PaymentTransactionRoute + '/:id'}
            element={SimpleSuspense(PaymentTransaction)}
          />

          <Route path={ExampleRoute + '/calendar'} element={<CalendarExample />} />
          <Route path={ExampleRoute + '/test'} element={<h1> We got here by choice </h1>} />
        </Routes>
      </Suspense>
    </main>
  );
};

export default Main;
