import $axios from 'axios';
import { encodeUnicode, getEnv } from '../utils';

export const getRandomUnsplashPhotos = async () => {
  try {
    const { data } = await $axios({
      headers: {
        Authorization: `Client-ID ${getEnv('REACT_APP_UNSPLASH_CLIENT_KEY')}`,
      },
      method: 'get',
      url: `https://api.unsplash.com/photos/random?count=50&orientation=landscape&query=event,party,fun,dance,abstract,gospel`,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getRandomPixabayPhotos = async () => {
  try {
    const { data } = await $axios({
      headers: {
        Authorization: `Client-ID ${getEnv('REACT_APP_UNSPLASH_CLIENT_KEY')}`,
      },
      method: 'get',
      url: `https://api.unsplash.com/photos/random?count=20&orientation=landscape&query=event,party,fun,dance,laughing,gospel,music`,

    });
    return data;
  } catch (error) {
    return null;
  }
};

export async function getIPDataInfo(asRaw = false) {
  try {
    const ipInfo = sessionStorage.getItem('ipdata');
    if (!ipInfo) {
      const resp = await $axios.get(
        `https://api.ipdata.co?api-key=${getEnv('REACT_APP_IP_ADDRESS')}`
      );
      const data = JSON.stringify(resp);
      sessionStorage.setItem('ipdata', data);
      return asRaw ? data : encodeUnicode(data);
    }
    return asRaw ? ipInfo : encodeUnicode(ipInfo);
  } catch (e) {
    console.log((e as Error)?.message);
    return '';
  }
}
