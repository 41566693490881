import { DateTime } from 'luxon';
import { IPaymentCredentials, IPaymentServiceConfig, IPaymentOptions } from '../interface/payment';
import { addPaymentTransaction } from '../services/payment';
import {
  IAcceptedCurrencies,
  ITicket,
  ITicketCountPayload,
  ITicketTransaction,
} from '../interface/ticket';
import { IUser } from '../interface/user';
import { getCurrencyIcon } from './general';
import { hasUserPaid } from './premiumUsers';

export const paymentInterval = (
  interval: string
): {
  duration: string;
  label: string;
  value: string;
} => {
  const monthly: string[] = ['monthly'];
  const biAnually: string[] = ['bi-anually'];
  const yearly: string[] = ['yearly'];
  const trial: string[] = ['trial'];

  if (monthly.includes(interval?.toLowerCase())) {
    return {
      duration: 'monthly',
      label: 'month',
      value: DateTime.now().plus({ months: 1 }).toISO(),
    };
  }
  if (biAnually.includes(interval?.toLowerCase())) {
    return {
      duration: 'bi-anually',
      label: 'six months',
      value: DateTime.now().plus({ month: 6 }).toISO(),
    };
  }
  if (yearly.includes(interval?.toLowerCase())) {
    return { duration: 'yearly', label: 'year', value: DateTime.now().plus({ year: 1 }).toISO() };
  }
  if (trial.includes(interval?.toLowerCase())) {
    return {
      duration: 'trial',
      label: 'seven days',
      value: DateTime.now().plus({ days: 7 }).toISO(),
    };
  }
  return {
    duration: 'others',
    label: 'three months',
    value: DateTime.now().plus({ month: 3 }).toISO(),
  };
};

export const handlePaymentResponse = async (
  response: any,
  config: IPaymentServiceConfig & IPaymentCredentials
) => {
  if (config.paymentProvider === 'flutterwave') {
    try {
      const res = await addPaymentTransaction({
        internalPlanId: config.internalPlanId,
        provider: config.paymentProvider,
        uniqueTransactionId: String(response.transaction_id),
      });
      return res;
    } catch (error: any) {
      console.error(error.response);
    }
  }
  if (config.paymentProvider === 'paystack') {
    try {
      const res = await addPaymentTransaction({
        internalPlanId: config.internalPlanId,
        provider: config.paymentProvider,
        uniqueTransactionId: String(response.reference),
      });
      return res;
    } catch (error: any) {
      console.error(error.response);
    }
  }
};

export const checkPaymentStatus = (status: string, provider: IPaymentOptions) => {
  const obj = {
    isSuccess: false,
    isFailed: false,
    isPending: true,
  };

  switch (provider) {
    case 'flutterwave':
      obj.isSuccess = status.toLowerCase().includes('successful');
      obj.isFailed = !obj.isSuccess;
      obj.isPending = !obj.isFailed && !obj.isSuccess;
      break;
    case 'paystack':
      obj.isSuccess = status.toLowerCase().includes('success');
      obj.isFailed = !obj.isSuccess;
      obj.isPending = !obj.isFailed && !obj.isSuccess;
      break;
    default:
      break;
  }

  return obj;
};

export const getDiscount = (mainAmount: number, type: string, value: number): number => {
  const amount = Number(mainAmount);
  if (type === 'percentage') {
    return (amount * value) / 100;
  }
  if (type === 'rate') {
    return value;
  }
  if (type === 'price') {
    return value;
  }
  return 0;
};

export const calculateTicketCategories = (categories: ITicketCountPayload[], ticket: ITicket) => {
  const data = categories?.reduce(
    (acc: any, curr: any) => ({
      total: Number((acc.total + Number(curr.price) * Number(curr.quantity)).toFixed(2)),
      quantity: acc.quantity + Number(curr.quantity),
      discount:
        acc.discount +
        getDiscount(
          Number(curr.price),
          ticket?.discount_value ? ticket?.discount_type! : 'none',
          ticket?.discount_value as number
        ) *
          Number(curr.quantity),
      types: [...acc.types, curr.type],
    }),
    { total: 0, quantity: 0, discount: 0, types: [] }
  );

  return {
    ...data,
    grandTotal: Number(
      (
        data.total - (data.quantity >= Number(ticket?.discount_start_size) ? data.discount : 0)
      ).toFixed(2)
    ),
  };
};

export const maxAccountNumber = (user: IUser) => {
  const max = 10;
  return user?.is_business && hasUserPaid(user).hasPaid
    ? max / 2
    : user?.is_enterprise && hasUserPaid(user).hasPaid
    ? max
    : 1;
};

export const setTransactionStatus = (status: string) => {
  switch (status) {
    case 'success':
      return 'paid';
    case 'otp':
      return 'pending';
    default:
      return status.toLowerCase();
  }
};

export const handleCurrencyFormat = (currency: IAcceptedCurrencies, value: string) => {
  return `${getCurrencyIcon(currency)}${value}`;
};

export const formatMoney = (num: number | string): string => {
  return Intl.NumberFormat().format(Number(num));
};

export const getTotalTicketCost = (
  currency: IAcceptedCurrencies,
  ticketInfo: ITicketCountPayload[],
  ticketTransaction: ITicketTransaction
) => {
  const totalTicketAmount = calculateTicketCategories(ticketInfo, {
    discount_start_size: ticketTransaction?.ticket_discount_start_size,
    discount_type: ticketTransaction?.ticket_discount_type,
    discount_value: ticketTransaction?.ticket_discount_value,
  } as any).grandTotal;
  return handleCurrencyFormat(currency, formatMoney(totalTicketAmount));
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case 'NGN':
      return '₦';
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'ZAR':
      return 'R';
    case 'KES':
      return 'KSh';
    case 'GHS':
      return '₵';
    case 'XOF':
      return 'CFA';
    default:
      return '';
  }
};

export const checkIfStatusIs = (status: any, type: string) => {
  return status.toLowerCase() === type;
};

export const generateUserPaymentMeta = (
  credentials: IPaymentServiceConfig & IPaymentCredentials
) => ({
  phoneNumber: credentials?.phoneNumber ?? credentials.user.phone_number,
  name: credentials?.firstName
    ? `${credentials?.firstName} ${credentials?.lastName}`
    : credentials.user.name ?? `${credentials.user.first_name} ${credentials.user.last_name}`,
  address: `${credentials?.addressLine1}, ${credentials?.addressLine2}`,
  city: credentials?.city,
  state: credentials?.state,
  country: credentials?.country,
  zipCode: credentials?.zipCode,
  userId: credentials?.user?.id,
  type: credentials?.type,
  plan: credentials?.plan,
  providerPlanId: credentials?.providerPlanId,
  interval: credentials?.sInterval,
  subscription: credentials?.subscription,
  ...(credentials?.metadata || {}),
});
