import React from 'react';
import { useUser } from '../../hooks/useUser';
import { useLocation } from 'react-router-dom';
import { PAID_ONLY_ROUTES } from '../../utils';
import PaymentRequiredError from '../../screens/Error/PaymentRequiredError';

interface IPaidOnlyRoute {
  children: React.ReactNode;
}

const PaidOnlyRoute: React.FunctionComponent<IPaidOnlyRoute> = ({ children }) => {
  const [, , { isOnStarterPlan }] = useUser();
  const { pathname } = useLocation();

  return (
    <>
      {isOnStarterPlan &&
      pathname === 'skip' &&
      PAID_ONLY_ROUTES.some((path) => pathname.includes(path)) ? (
        <PaymentRequiredError />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default PaidOnlyRoute;
