import { DateTime, DurationUnits } from 'luxon';
export const formatDate = ({
  date,
  dateTimeFormat,
  format,
}: {
  date: string;
  format?: any;
  dateTimeFormat?: any;
}) => {
  if (format) {
    return DateTime.fromISO(date).toFormat(format);
  }
  if (dateTimeFormat) {
    return DateTime.fromISO(date).toLocaleString(dateTimeFormat);
  }
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
};

export const calculateDifferenceInTime = (
  start: string,
  end: string,
  unit: DurationUnits = 'minutes'
) => {
  const first = DateTime.fromISO(start);
  const second = DateTime.fromISO(end);
  return second.diff(first, unit).minutes;
};

export const timeAgo = (date: string) => DateTime.fromISO(date).toRelative();

export interface CustomDateValue {
  date: string;
  time: string;
}

export interface CustomDateTimeObject {
  title?: any;
  hour?: number;
  minutes?: number;
  seconds?: number;
  HH?: number;
  MM?: number;
  SS?: number;
  name?: string;
}

export interface ValidEventTimer {
  id?: string;
  title: string;
  description?: string;
  meta?: CustomDateTimeObject;
  [key: string]: any;
}
export const convertDateToString = (date: Date): CustomDateValue => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const value = `${day}/${month}/${year}`;

  // create and return hour, minutes and seconds
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const time = `${hour}:${minutes}:${seconds}`;
  return { date: value, time };
};

export const setTime = (
  date: Date,
  value: CustomDateTimeObject,
  increment = true,
  decrement = false
): Date => {
  let hour = value.hour || value.HH || 0;
  let minutes = value.minutes || value.MM || 0;
  let seconds = value.seconds || value.SS || 0;
  if (increment) {
    hour = date.getHours() + hour;
    minutes = date.getMinutes() + minutes;
    seconds = date.getSeconds() + seconds;
  } else if (decrement) {
    hour = date.getHours() - hour;
    minutes = date.getMinutes() - minutes;
    seconds = date.getSeconds() - seconds;
  } else {
    hour = 0;
    minutes = 0;
    seconds = 0;
  }
  date.setHours(hour);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};

export const ApplicationDate = new Date();

export const getHourMinutesAndSecondsFromMilliseconds = (milliseconds: number): string => {
  const total = milliseconds / 1000;
  const seconds = Math.floor(total % 60);
  const minutes = Math.floor(total / 60) % 60;
  const hours = Math.floor(total / 3600);
  const hourText = hours >= 1 ? `${hours <= 9 ? '0' : ''}${hours}:` : '00:';
  const minuteText = minutes >= 1 ? `${minutes <= 9 ? '0' : ''}${minutes}:` : '00:';
  const secondText = seconds >= 0 ? `${seconds <= 9 ? '0' : ''}${seconds}` : '00';
  return `${hourText}${minuteText}${secondText}`;
};

export const generateBackgroundStyle = (styles = {}, chars = '') => {
  const randomNumber = chars || Math.floor(Math.random() * 100);
  return {
    backgroundImage: `url("https://source.unsplash.com/random?nature,gospel,music,dark+abstract,${randomNumber}")`,
    backgroundSize: 'cover',
    ...styles,
  };
};

export const isExpiredDate = (date: string) => {
  const date1 = DateTime.fromISO(date);
  const date2 = DateTime.now();
  return date1.diff(date2, 'days').days < 0;
};
