export const hasUserPaid = (user: { [key: string]: any }) => {
  const isNotStarter = !user?.is_starter;
  const isPremium = user.is_enterprise || user.is_business;
  const isActiveSubscription =
    user?.subscription_expired_at && user?.subscription_id && user?.subscription_status;
  const hasPaid =
    (isPremium && isNotStarter && isActiveSubscription) || (!isNotStarter && !isPremium);
  const isOnStarterPlan = !isPremium || (isPremium && !hasPaid);
  return {
    hasPaid,
    isNotStarter,
    isPremium,
    isActiveSubscription,
    isOnStarterPlan,
    isOnPremiumPlan: !isOnStarterPlan,
  };
};
