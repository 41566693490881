export const DashboardRoute = '/';
export const SettingsRoute = '/settings';
export const AccountSecurityRoute = `${SettingsRoute}/account-security`;
export const BillingRoute = 'billing';
export const BillingSettingsRoute = `${SettingsRoute}/billing`;
export const PaymentMethodsRoute = `${SettingsRoute}/payment-methods`;
export const NotificationsRoute = `${SettingsRoute}/notifications`;
export const NotificationsDashboardRoute = '/notifications';
export const AppIntegrationsRoute = `${SettingsRoute}/app-integrations`;
export const DeveloperToolsRoute = `${SettingsRoute}/developer-tools`;
export const DevicesRoute = 'devices';
export const ExampleRoute = '/example';
export const EventsRoute = '/events';
export const EventsFormRoute = '/events/create';
export const ViewEventsRoute = '/events/view';
export const ActivitiesRoute = '/activities';
export const AuthSSORoute = '/sso/auth';
export const Error500Route = '/error-500';
export const GuestsRoute = '/guests';
export const ViewEventAttendanceRoute = '/pub/event';
export const ViewEventAttendanceRouteNew = '/e/:id';
export const GuestsFormRoute = '/guests/form';
export const GuestsProfileRoute = '/guests/profile';
export const TicketsRoute = '/ticket';
export const TicketsCreateRoute = '/ticket/form';
export const ViewTicketsRoute = '/ticket/view';
export const PaymentTransactionRoute = '/transaction';
export const StageTimeEventRoute = '/stage';
export const POSPaymentRoute = '/point-of-sale';
export const LogoutRoute = '#logging-out-' + Date.now();
export const CompleteOrderRoute = '/complete-order';
export const ViewAttendanceTicketsRoute = '/view-tickets';
export const PresenterModeRoute = '/presenter';
export const ViewStageTimersRoute = '/timers';
export const PayoutSettingsRoute = `${SettingsRoute}/payout`;
export const PayoutRoute = '/payout';
export const PayoutTransactionDetailsRoute = '/payout/view';
export const PayoutTransactionsRoute = '/payout/view';
export const ViewTicketSalesRoute = '/ticket-sales';
export const ProfileSettingRoute = '/settings';
export const TimerCountdownRoute = 'timers';
export const PaymentRequiredError = '/payment-error';
export const ViewEventAnalyticsRoute = '/events/analytics/:eventId';
export const ConfirmQRCodeRouteNew = '/events/scan/:eventId';
