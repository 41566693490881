// noinspection DuplicatedCode

import mixpanel from 'mixpanel-browser';
import { getEnv } from '../utils';
try {
  mixpanel.init(getEnv('MIXPANEL_TOKEN'), {
    debug: true,
    track_links_timeout: 5000,
    ip: true,
    ignore_dnt: true,
  });

  if (!mixpanel?.has_opted_in_tracking?.()) {
    mixpanel.opt_in_tracking();
  }
} catch (e) {
  console.error(e);
}
// })

const useAnalytics = () => {
  const params = new URLSearchParams(window.location.search);

  const track = (eventName: string, properties?: any, callback?: () => any) => {
    try {
      if (mixpanel?.has_opted_in_tracking?.()) {
        mixpanel.track(eventName, {
          properties: {
            ...(properties ?? {}),
            app: 'kirgawa-landing-web',
            urlInfo: {
              search: window.location.search,
              hash: window.location.hash,
              pathname: window.location.pathname,
              href: window.location.href,
              urlParams: Object.fromEntries(params),
            },
          },
        });
      }
    } catch (e) {
      console.error((e as any)?.message);
    }
    if (callback) {
      callback();
    }
  };

  const identify = (userId: string) => {
    try {
      mixpanel.identify(userId);
    } catch (e) {
      console.error(e);
    }
  };

  const alias = (userId: string) => {
    try {
      mixpanel.alias(userId);
    } catch (e) {
      console.error(e);
    }
  };

  const reset = () => {
    try {
      mixpanel.reset();
    } catch (e) {
      console.error(e);
    }
  };

  const people = {
    set: (properties: any) => {
      try {
        mixpanel.people.set(properties);
      } catch (e) {
        console.error(e);
      }
    },
    increment: (properties: any) => {
      try {
        mixpanel.people.increment(properties);
      } catch (e) {
        console.error(e);
      }
    },
  };

  return {
    track,
    identify,
    alias,
    reset,
    people,
  };
};

export default useAnalytics;
