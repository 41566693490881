import { ITicketFormAtomState } from '../interface/ticket';
import { IBillingInfoData } from '../interface/billingInfo';
import { IContactData } from '../interface/contacts';
import { ISideNav } from '../interface/sideNav';

export const sideNavAtomStateInitState = (): ISideNav => ({
  main: 'Dashboard',
  sub: '',
  toggle: '',
});

export const billingInfoAtomStateInitStae = (): IBillingInfoData => ({
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  firstName: '',
  lastName: '',
  middleName: '',
  phoneNumber: '',
  state: '',
  zipCode: '',
  paymentProvider: 'paystack',
});

export const contactFormAtomStateInitState = (): IContactData => ({
  id: '',
});

export const ticketFormAtomStateInitState = (): ITicketFormAtomState => ({
  edit: false,
});

export const dashboardStatistic = () => ({
  top10: [],
  activePercentageStats: { online: 0, offline: 0, total: 0 },
  attendance: 0,
  ticket: 0,
  activity: 0,
  events: 0,
  activeUrls: { data: [], meta: { total: 0 } },
  offlineUrls: { data: [], meta: { total: 0 } },
  loading: true,
  loaded: false,
});
