import React from 'react';
import { IPaymentOptions } from '../interface/payment';

type IPaymentOptionsArray = {
  name: React.ReactNode;
  value: IPaymentOptions;
};

export const paymentOptionsArray: IPaymentOptionsArray[] = [
  // {
  //   name: (
  //     <>
  //       <span style={{ color: '#263238' }}>Flutterwave</span>{' '}
  //       <span style={{ color: '#7F8C8D' }}>(Credit Card/USSD/Bank)</span>
  //     </>
  //   ),
  //   value: 'flutterwave',
  // },
  // {
  //   name: (
  //     <>
  //       <span style={{ color: '#263238' }}>Paypal</span>{' '}
  //       <span style={{ color: '#7F8C8D' }}>NGN will be converted in USD</span>
  //     </>
  //   ),
  //   value: 'paypal',
  // },
  {
    name: (
      <>
        <span style={{ color: '#263238' }}>Paystack</span>{' '}
        <span style={{ color: '#7F8C8D' }}>(Credit Card/USSD/Bank)</span>
      </>
    ),
    value: 'paystack',
  },
];
